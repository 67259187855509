import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="catering-expense"
export default class extends Controller {
  static targets = ["destroyCheckBox", "deleteBtn", "wholeDayCheckBox", "wholeDayHide", "nestedForm"]
  connect() {
    this.wholeDayCheckBoxTarget.addEventListener('change', (event) => {
      if (event.target.checked) {
        this.wholeDayHideTargets.forEach((hideTarget) => {
          hideTarget.classList.add('d-none')
        })
      } else {
        this.wholeDayHideTargets.forEach((hideTarget) => {
          hideTarget.classList.remove('d-none')
        })
      }
    })

    this.destroyCheckBoxTarget.addEventListener('change', (event) => {
      if (event.target.checked) {
        this.nestedFormTarget.classList.remove('show')
      } else {
        this.nestedFormTarget.classList.add('show')
      }
    })

    this.deleteBtnTarget.addEventListener('click', () => {
      this.destroyCheckBoxTarget.click()
    })

    // initial state check
    this.wholeDayCheckBoxTarget.dispatchEvent(new Event('change'))
  }

  uncheckDestroy() {
    this.destroyCheckBoxTarget.checked = true
  }
}
