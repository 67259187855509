import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="deviation-reason"
export default class extends Controller {
  static targets = [ "trigger", "reason" ]
  connect() {
    if (! this.hasTriggerTarget ) return
    if (! this.hasReasonTarget ) return

    this.originalStartAddress = this.triggerTarget.value

    this.triggerTarget.addEventListener('input', () => {
      if (this.triggerTarget.value !== this.originalStartAddress) {
        this.reasonTarget.classList.remove('d-none')
        this.reasonTarget.querySelector('textarea').required = true
      } else {
        this.reasonTarget.classList.add('d-none')
        this.reasonTarget.querySelector('textarea').required = false
      }
    })
  }
}
