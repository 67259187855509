import {Controller} from "@hotwired/stimulus"
// important: using `import { Cropper } from 'cropperjs'` does not work
import Cropper from 'cropperjs'

// Connects to data-controller="cropper"
export default class extends Controller {
  static targets = ['image', 'croppedImageInput', 'croppedDataInput']
  connect() {
    if (!this.hasImageTarget) return

    this.cropper = new Cropper(this.imageTarget, {
      viewMode: 1,
      autoCropArea: 1.0, // 100% of the image
      movable: false,
      // on crop end event call crop action and write image string into hidden field cropped_image
      cropend: (event) => {
        this.crop(event)
      }
    })
    window.cropper = this.cropper
  }

  rotate(event) {
    const degrees = parseInt(event.target.dataset.rotation);
    window.cropper.rotate(degrees)
  }

  crop(event) {
    event.preventDefault();
    this.croppedDataInputTarget.value = JSON.stringify(window.cropper
      .getImageData())
    this.croppedImageInputTarget.value = window.cropper
      .getCroppedCanvas()
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream")
  }

  reset(event) {
    this.croppedImageInputTarget.value = ''
  }
}
