import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["travelExpenseItems", "travelExpenseItemTemplate"]

  connect() {
    this.index = this.travelExpenseItemsTarget.children.length
  }

  addTravelExpenseItem(event) {
    event.preventDefault()
    const content = this.travelExpenseItemTemplateTarget.innerHTML
      .replace(/NEW_RECORD/g, new Date().getTime())
      .replace(/travel_expense\[travel_expense_items_attributes\]\[\d+\]/g, `travel_expense[travel_expense_items_attributes][${this.index}]`)
    this.travelExpenseItemsTarget.insertAdjacentHTML('beforeend', content)
    this.index++
  }

  removeTravelExpenseItem(event) {
    event.preventDefault()
    const lineItem = event.target.closest('.travel-expense-item')
    lineItem.remove()
  }
}