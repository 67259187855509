import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="openstreet-map"
export default class extends Controller {
  static targets = [ "container", "largerMapLink" ]
  static values = { latitude: Number, longitude: Number }

  connect() {
    this.updateLargerMapLink()
  }

  loadMap() {
    const iframe = document.createElement('iframe')
    iframe.id = "map-location-preview"
    iframe.width = '100%'
    iframe.height = '100%'

    const bbox = this.calculateBoundingBox()
    iframe.src = `https://www.openstreetmap.org/export/embed.html?bbox=${bbox}&layer=mapnik&marker=${this.latitudeValue},${this.longitudeValue}`
    iframe.style.border = 'none'

    this.containerTarget.innerHTML = ''
    this.containerTarget.appendChild(iframe)
  }

  calculateBoundingBox() {
    // Adjust these values to change the zoom level and centering
    const latSpan = 0.07;  // Adjust this to zoom in or out
    const lonSpan = latSpan * (980 / 340);  // Maintain aspect ratio

    const south = this.latitudeValue - latSpan / 2;
    const north = this.latitudeValue + latSpan / 2;
    const west = this.longitudeValue - lonSpan / 2;
    const east = this.longitudeValue + lonSpan / 2;

    return `${west},${south},${east},${north}`;
  }

  updateLargerMapLink() {
    this.largerMapLinkTarget.href = `https://www.openstreetmap.org/?mlat=${this.latitudeValue}&mlon=${this.longitudeValue}#map=15/${this.latitudeValue}/${this.longitudeValue}`
  }
}
