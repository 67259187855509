import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="signature-pad"
export default class extends Controller {
  static targets = [ "canvas", "hiddenField" ]

  connect() {
    this.ctx = this.canvasTarget.getContext('2d')
    this.isDrawing = false
    this.lastX = 0
    this.lastY = 0

    this.resizeCanvas()
    window.addEventListener('resize', this.resizeCanvas.bind(this))

    this.addEventListeners()
  }

  resizeCanvas() {
    const rect = this.canvasTarget.getBoundingClientRect()
    const dpr = window.devicePixelRatio || 1
    this.canvasTarget.width = rect.width * dpr
    this.canvasTarget.height = rect.height * dpr
    this.ctx.scale(dpr, dpr)
    this.canvasTarget.style.width = `${rect.width}px`
    this.canvasTarget.style.height = `${rect.height}px`
    this.ctx.lineJoin = 'round'
    this.ctx.lineCap = 'round'
    this.ctx.lineWidth = 2
  }

  addEventListeners() {
    this.canvasTarget.addEventListener('pointerdown', this.startDrawing.bind(this))
    this.canvasTarget.addEventListener('pointermove', this.draw.bind(this))
    this.canvasTarget.addEventListener('pointerup', this.stopDrawing.bind(this))
    this.canvasTarget.addEventListener('pointerout', this.stopDrawing.bind(this))
  }

  startDrawing(e) {
    e.preventDefault()
    this.isDrawing = true;
    [this.lastX, this.lastY] = this.getCoordinates(e)
    this.canvasTarget.setPointerCapture(e.pointerId)
  }

  draw(e) {
    if (!this.isDrawing) return
    e.preventDefault()

    const [x, y] = this.getCoordinates(e)

    this.ctx.beginPath()
    this.ctx.moveTo(this.lastX, this.lastY)
    this.ctx.lineTo(x, y)
    this.ctx.stroke()

    ;[this.lastX, this.lastY] = [x, y]
  }

  stopDrawing(e) {
    if (this.isDrawing) {
      e.preventDefault()
      this.isDrawing = false
      this.canvasTarget.releasePointerCapture(e.pointerId)
    }
  }

  getCoordinates(e) {
    const rect = this.canvasTarget.getBoundingClientRect()
    return [
      e.clientX - rect.left,
      e.clientY - rect.top
    ]
  }

  handleSubmit(event) {
    event.preventDefault()
    this.saveSignature()
    console.log(this.hiddenFieldTarget.value)
    event.target.submit()
  }

  saveSignature() {
    this.hiddenFieldTarget.value = this.canvasTarget.toDataURL('image/png')
  }

  resetCanvas(e) {
    e.preventDefault()
    this.ctx.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height)
    this.hiddenFieldTarget.value = ''
  }
}