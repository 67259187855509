import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="delete-nested-form"
export default class extends Controller {
  static targets = [ "deleteCheckBox" ]

  removeItem(event) {
    event.preventDefault();
    if (confirm(event.currentTarget.getAttribute('data-turbo-confirm'))) {
      this.deleteCheckBoxTarget.checked = true
      this.element.classList.add('d-none')
    }
  }
}
