import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hide-on-check"
export default class extends Controller {
  static targets = [ "trigger", "toggle" ]
  connect() {
    if (!this.triggerTarget) return

    this.triggerTarget.addEventListener('change', () => {
      this.triggerChanged()
    })

    this.triggerChanged()
  }

  triggerChanged() {
    if (this.triggerTarget.checked) {
      this.hideToggles()
    } else {
      this.showToggles()
    }
  }

  hideToggles() {
    if (!this.toggleTarget) return

    this.toggleTargets.forEach((toggle) => {
      toggle.classList.add('d-none')
    })
  }

  showToggles() {
    if (!this.toggleTarget) return

    this.toggleTargets.forEach((toggle) => {
      toggle.classList.remove('d-none')
    })
  }
}
