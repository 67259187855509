// app/javascript/controllers/select_controller.js

import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { get } from '@rails/request.js'

export default class extends Controller {
  // Define static value to fetch the URL from HTML data attributes.
  static values = {
    url: String,
    placeholder: String,
    selected: String,
    objectId: {
      type: String,
      default: 'null'
    },
    klass: {
      type: String,
      default: 'TimeSheet'
    },
    paramName: {
      type: String,
      default: 'time_sheet'
    }
  };

  static outlets = ['cascading-select']

  // Triggered when the Stimulus controller is connected to the DOM.
  connect() {
    this.initializeTomSelect();
    this.setSelected();
    // Workaround to prevent content shifting when the input is unfocused while clicking on save button
    // resulting in having to click twice on the save button
    this.element.tomselect.on('item_add', () => {
      this.element.tomselect.blur();
    })

    // When an item is selected we want to hide the placeholder
    // When the item is removed we want to show the placeholder
    // Although the placeholder should hide on selecting an item, it doesn't
    // See: https://tom-select.js.org/docs/ => hidePlaceholder
    this.element.tomselect.on('change', (item) => {
      if (!item) {
        this.element.tomselect.settings.hidePlaceholder = false;
        this.element.tomselect.inputState();
      } else {
        this.element.tomselect.settings.hidePlaceholder = true;
        this.element.tomselect.inputState();
      }
    })

    // no url = this is the dependent select so no need to update self
    if (this.hasUrlValue) {
      this.element.addEventListener('change', () => {
        this.getCascadingList(this.select.activeOption.dataset.value, this.objectIdValue, this.klassValue, this.paramNameValue)
      })
    }
  }

  getCascadingList(customer, object_id, object_klass, param_name) {
    // overriding customer to make touch work on mobile
    customer = this.element.tomselect.getValue()
    get(`/staff/customers/find-wards?customer=${customer}&object_id=${object_id}&object_klass=${object_klass}&param_name=${param_name}`, {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    }).then(response => {
      if (response.ok) {
        // request.js handles this
      } else {
        console.log(response)
      }
    })
  }

  // Triggered when the Stimulus controller is removed from the DOM.
  disconnect() {
    this.destroyTomSelect();
  }

  // Initialize the TomSelect dropdown with the desired configurations.
  initializeTomSelect() {
    // Return early if no element is associated with the controller.
    if (!this.element) return;

    // Construct URL for fetching data which comes from the static value.
    // https://tom-select.js.org/examples/remote/
    const url = `${this.urlValue}.json`;

    // Fetch data for the dropdown.
    const fetchData = (search, callback) => {
      // prevent loading remotely when there is no url
      if (!this.hasUrlValue) return callback()

      // Add cache-busting parameters
      fetch(url, {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        }
      })
        .then(response => response.json())  // Convert response to JSON.
        .then(data => callback(data))       // Send data to TomSelect.
        .catch(() => callback());           // Handle any errors.
    };

    // Create a new TomSelect instance with the specified configuration.
    // see: https://tom-select.js.org/docs/
    // value, label, search, placeholder, etc can all be passed as static values instead of hard-coded.
    this.select = new TomSelect(this.element, {
      // plugins: ['remove_button'],
      valueField: 'id',
      labelField: 'name',
      searchField: ['name'],
      maxItems: 1,
      selectOnTab: true,
      placeholder: this.placeholderValue,
      closeAfterSelect: true,
      hidePlaceholder: true,
      copyClassesToDropdown: true,
      preload: true,
      create: false, // activate this to allow users to add new entries if none is found
      createOnBlur: false,
      openOnFocus: true,
      highlight: true,
      sortField: { field: "name", direction: "asc" },
      load: fetchData
    });
  }

  setSelected() {
    if (! this.hasSelectedValue) return

    this.select.addOption({name: this.selectedValue})
    this.select.addItem(this.selectedValue)

    if (this.objectIdValue === 'null') return
    this.getCascadingList(this.selectedValue, this.objectIdValue, this.klassValue, this.paramNameValue)
  }

  // Cleanup: Destroy the TomSelect instance when the controller is disconnected.
  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}