import { Controller } from "@hotwired/stimulus"
import Collapse from "bootstrap/js/dist/collapse"
import Tooltip from "bootstrap/js/dist/tooltip";

// Connects to data-controller="collapse"
export default class extends Controller {
  connect() {
    this.collapse = new Collapse(this.element, { toggle: false })
  }
}
