import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-row-click"
export default class extends Controller {
  static targets = ['link']
  connect() {
    this.element.addEventListener('click', () => {
      this.linkTarget.click();
    })
  }
}
