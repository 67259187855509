import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upload-preview"
export default class extends Controller {
  static targets = [ "input", "preview" ]

  static values = {
    mode: {
      type: String,
      default: 'default'
    }
  };
  connect() {
    if ( !this.hasInputTarget ) {
      console.log('no input target defined')
      return
    }

    if ( !this.hasPreviewTarget ) {
      console.log('no preview target defined')
      return
    }

    console.log('modeValue: ')
    console.log(this.modeValue)

    this.inputTarget.addEventListener("change", this.previewFiles.bind(this))
  }

  // builds a preview of the file(s) to be uploaded as embedded objects utilizing the browser's native preview functionality
  previewFiles() {
    this.previewTarget.innerHTML = ""
    Array.from(this.inputTarget.files).forEach(file => {
      // console.dir(file)
      const displayable_image_types = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp']
      if (displayable_image_types.includes(file.type)) {
        // this code is for iamge preview
        const imageTag = document.createElement("img")
        imageTag.src = URL.createObjectURL(file)
        imageTag.classList.add('img-fluid')
        // make cropper available on the selected image
        let controller_to_add = '';
        if (this.modeValue === 'none') {
          controller_to_add = imageTag.dataset.controller
        } else {
          controller_to_add = [imageTag.dataset.controller, 'cropper'].join(' ')
        }
        imageTag.dataset.controller = controller_to_add
        imageTag.dataset.cropperTarget = 'image'
        this.previewTarget.appendChild(imageTag)
      } else if(file.type === 'image/heic' || file.type === 'image/heif') {
        const heif_heic_error = document.createElement('p')
        heif_heic_error.classList.add('alert')
        heif_heic_error.classList.add('alert-danger')
        heif_heic_error.innerHTML = 'Die Vorschauf von HEIC/HEIF Bildern wird derzeit nicht unterstützt.'
        this.previewTarget.appendChild(heif_heic_error)
      } else {
        // this code is for pdf preview
        const embeddedFile = document.createElement("embed")
        embeddedFile.src = URL.createObjectURL(file)
        embeddedFile.width = 800
        embeddedFile.height = 600
        this.previewTarget.appendChild(embeddedFile)
      }
    })
  }
}